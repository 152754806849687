.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/************** FORM ****************/

.form-box{
  min-width: 100%;
  height: 90vh;
  /* border: dotted #F55A00 5vw; */
  /* border: 1px solid red; */
  margin: 0vw;
  padding: 5vw;
  background-color: beige;

  /* position:relative for .prevBtn and .nextBtn position:absolute; */
  position:relative; 
}

.field1{
  position:relative; 
  /* position:relative for .prevBtn and .nextBtn position:absolute; */
}

#feedback-form {
  width: 380px;
  margin: 0 auto;
  margin-top: 40px;
  background-color: #fcfcfc;
  padding: 20px 50px 40px;
  box-shadow: 1px 4px 10px 1px #aaa;
  font-family: sans-serif;
  text-align: center;
}

#feedback-form * {
    box-sizing: border-box;
}

#feedback-form h2{
  text-align: center;
  margin-bottom: 30px;
}

#feedback-form input {
  margin-bottom: 15px;
}

#feedback-form input[type=email] {
  display: block;
  height: 32px;
  padding: 15px 36px;
  width: 100%;
  border: none;
  background-color: #f3f3f3;
}

#feedback-form label {
  color: #777;
  font-size: 1em;
}

#feedback-form input[type=checkbox] {
  float: left;
}

#feedback-form input:not(:checked) + #feedback-phone {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

#feedback-form #feedback-phone {
  transition: .3s;
}

#feedback-form button[type=submit] {
  display: block;
  margin: 20px auto 0;
  width: 250px;
  height: 40px;
  border-radius: 5px;
  border: none;
  color: #eee;
  font-weight: 700;

  
  background: #207cca; /* Old browsers */
 
}
